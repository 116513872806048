import InnerPageContent from "../../components/inner-page-content";

import "./styles.css";

const FreeOnlineBibleStudy = () => {
    
    let firstPara = {
        imageUrl: "./images/bible-study-edited.jpg",
        heading: "WOULD YOU LIKE TO STUDY BIBLE WITH US?",
        text: <>
            <br/><br/>
            <p>We are open to studying Bible online with you. You will learn about one true God and his Divinely inspired written Word, the Bible. </p>
            <p>We have some lessons in this page for you to download and learn at your own pace. Please reach out to us if you want to learn more, have any questions or want an interactive class and one of our members will help you.</p>
            <ol className="resourceList">
                <li>
                    <strong>God's chain of Salvation</strong>
                    <br />

                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vuYAhnW2Mhg?si=akppSSeMkMDAV5pn" title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                      
                    <br/>
                    <a href="./study-materials/GODSChainSalv.ppt" className="floatLeft" title="Download"><button type="button" className="btn btn-info">Get a local copy of God's chain of Salvation</button></a>
                </li>
            </ol>
            </>
     }
    return ( 
         <>
            <InnerPageContent {...firstPara} />
        </>
     );
}
 
export default FreeOnlineBibleStudy;